import { OperationalStatus } from "./receiver.metadata";
import { ReceiverConfigurationStatus, ReceiverUpdateStatus } from "./receiver.entity";

export interface ComponentDetail
{
    id: string;
    type: string;
    last_received_message?: [number, number]
}

export interface ReceiverDetail
{
    ids: { [key: string]: string };
    type?: string;
    latitude?: number;
    longitude?: number;
    wgs84_altitude?: number;
    temperature?: number;
    relative_humidity?: number;
    pressure?: number;
    last_update?: [number, number];
    last_gnss_fix?: [number, number];
    components?: ComponentDetail[];
    is_online?: boolean;
    power_consumption?: number;

    label?: string;
    primary_id: string;

    model?: string;
    operational_status?: OperationalStatus;
    firmware?: string;
    update_status?: ReceiverUpdateStatus;
    is_update_server_connected?: boolean;
    rssi_thresholds?: { [key: string]: number };
    configuration_status?: ReceiverConfigurationStatus;
    additional_permissions?: string[];

    // undocumented, only relevant for involi live
    profileNames?: string[];
}