import { EntityKind } from "../entity";
import { GlobalPermissionCategory } from "./global-permissions";

export interface PermissionDefinition
{
    id: string;
    global: boolean;
}

export const permissions: PermissionDefinition[] = [];

export function createPermission(type: EntityKind, name: string): string
{
    const permissionId = getPermissionId(type, name);
    if(permissions.some((p: PermissionDefinition) => p.id == permissionId))
        throw new Error(`Permission ${permissionId} is defined twice`);
    permissions.push({ id: permissionId, global: false });
    return permissionId;
}

export function createGlobalPermission(type: GlobalPermissionCategory, name: string): string
{
    const permissionId = getPermissionId(type, name);
    if(permissions.some((p: PermissionDefinition) => p.id == permissionId))
        throw new Error(`Permission ${permissionId} is defined twice`);
    permissions.push({ id: permissionId, global: true });
    return permissionId;
}

export function getPermissionId(type: string, name: string): string
{
    return `${type}:${name}`;
}

export const PermissionRead = 'read';
export const PermissionWrite = 'write';
export const PermissionUpdate = 'update';
