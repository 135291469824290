import { IsNotEmpty, IsString } from 'class-validator';
import { Entity } from '../entity/entity.js';

export class ConfigurationProfileEntity extends Entity
{
    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    body!: string;
}