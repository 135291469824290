import { IsArray, IsNotEmpty, IsString } from 'class-validator';

export class ProfileDto
{
    @IsString()
    @IsNotEmpty()
    profileId!: string;
}

export class ProfilesDto
{
    @IsArray()
    @IsString({ each: true })
    profileIds!: string[];
}