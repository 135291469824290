import { Type } from 'class-transformer';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';

export class DhcpFallback
{
    @IsString()
    domain_name_server!: string;

    @IsString()
    ip_address!: string;

    @IsString()
    router!: string;
}

export class Ntp
{
    @IsString()
    @IsOptional()
    ntp_server?: string;
}

export class TowerCollector1090
{
    @IsBoolean()
    adsb_enabled!: boolean;

    @IsBoolean()
    mode_ac_enabled!: boolean;

    @IsBoolean()
    mode_s_enabled!: boolean;
}

export class Vpn
{
    @IsBoolean()
    default_gateway!: boolean;
}

export class CombinedConfig
{
    @Type(() => DhcpFallback)
    @ValidateNested()
    dhcp_fallback!: DhcpFallback;

    @Type(() => Ntp)
    @ValidateNested()
    ntp!: Ntp;

    @Type(() => TowerCollector1090)
    @ValidateNested()
    tower_collector_1090!: TowerCollector1090;

    @Type(() => Vpn)
    @ValidateNested()
    vpn!: Vpn;
}

export class GetReceiverConfigDto
{
    @Type(() => CombinedConfig)
    @ValidateNested()
    @IsOptional()
    config?: CombinedConfig;
}

export type SetReceiverConfigDto = CombinedConfig;