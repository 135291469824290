import { Entity } from '../entity/entity.js';
import { IsBoolean, IsLatitude, IsLongitude, IsNumber, IsOptional, IsString } from 'class-validator';

export enum ReceiverUpdateStatus
{
    InProgress = 'in-progress',
    Aborting = 'aborting',
    Aborted = 'aborted',
    Success = 'success',
    Failed = 'failed'
}

export enum ReceiverConfigurationStatus
{
    Pending = 'pending',
    Applied = 'applied',
    Rejected = 'rejected',
    TimedOut = 'timed-out'
};

function modelFromSerialNumber(sn: string): string | undefined
{
    if(sn.length < 2)
        return undefined;

    const snPrefix = sn.slice(0, 2);

    if(snPrefix == 'AG' || snPrefix == 'AL')
        return 'G-1090';
    if(snPrefix == 'AF')
        return 'G-1090 FLARM';
    if(snPrefix == 'AU')
        return 'G-1090 UAT';
    if(snPrefix == 'AK')
        return 'Lightguard LGR';

    return undefined;
}

export class ReceiverEntity extends Entity
{
    @IsOptional()
    @IsString()
    model?: string;

    firmware?: string;
    managementId?: string;
    updateStatus?: ReceiverUpdateStatus;
    deploymentId?: string;
    configurationStatus?: ReceiverConfigurationStatus;

    @IsOptional()
    @IsString()
    serialNumber?: string;

    @IsOptional()
    @IsLatitude()
    pinnedLatitude?: number;

    @IsOptional()
    @IsLongitude()
    pinnedLongitude?: number;

    @IsOptional()
    @IsNumber()
    pinnedWgs84Altitude?: number;

    @IsOptional()
    @IsBoolean()
    openVpnConnected? : boolean;

    @IsOptional()
    @IsBoolean()
    menderConnected?: boolean;

    constructor(baseEntity?: Partial<ReceiverEntity>)
    {
        super();
        if(!baseEntity) return;
        this.serialNumber = baseEntity.serialNumber;
        if(baseEntity.id)
            this.id = baseEntity.id;
        if(baseEntity.model || !this.serialNumber)
            this.model = baseEntity.model;
        else
            this.model = modelFromSerialNumber(this.serialNumber);
    }
}