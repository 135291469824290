import { IsNotEmpty, IsString } from 'class-validator';

export class CreateProfileDto
{
    @IsString()
    @IsNotEmpty()
    organizationId!: string;

    @IsString()
    @IsNotEmpty()
    name!: string;

    @IsString()
    @IsNotEmpty()
    body!: string;
}