function compareFirmwares(a: [number, number, number], b: [number, number, number]): number
{
    if(a[0] != b[0]) return a[0] < b[0] ? -1 : 1;
    if(a[1] != b[1]) return a[1] < b[1] ? -1 : 1;
    if(a[2] != b[2]) return a[2] < b[2] ? -1 : 1;
    return 0;
}

function parseVersion(version: string): [number, number, number]
{
    return version.split('.').map((s: string) => parseInt(s)) as [number, number, number];
}

export function getNextFirmwareToInstall(current: string, mandatory: string[], reference: string): string | undefined
{
    const versionRegex = /^\d+\.\d+\.\d+$/;
    if(!current.match(versionRegex) || !reference.match(versionRegex))
        return undefined;

    const currentParsed = parseVersion(current);
    const referenceParsed = parseVersion(reference);

    if(compareFirmwares(currentParsed, referenceParsed) >= 0)
        return undefined;

    const mandatoryParsed = mandatory.filter((version: string) => version.match(versionRegex))
                                    .map(parseVersion)
                                    .sort(compareFirmwares);

    for(let itemParsed of mandatoryParsed)
    {
        if(compareFirmwares(currentParsed, itemParsed) < 0)
            return itemParsed.join('.');
    }

    return reference;
}