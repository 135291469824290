import { EntityKind } from '../entity/entity-kind.js';
import { createPermission, PermissionRead, PermissionUpdate, PermissionWrite } from '../permission/permissions.js';

export const PermissionReceiverRead = createPermission(EntityKind.Receiver, PermissionRead);
export const PermissionReceiverWrite = createPermission(EntityKind.Receiver, PermissionWrite);
export const PermissionReceiverUpdate = createPermission(EntityKind.Receiver, PermissionUpdate);
export const PermissionReceiverRssiThresholdsRead = createPermission(EntityKind.Receiver, 'rssi-thresholds-read');
export const PermissionReceiverPasswordRead = createPermission(EntityKind.Receiver, 'password-read');
export const PermissionReceiverConfigRead = createPermission(EntityKind.Receiver, 'config-read');
export const PermissionReceiverConfigWrite = createPermission(EntityKind.Receiver, 'config-write');
export const PermissionReceiverApplyConfigProfile = createPermission(EntityKind.Receiver, 'apply-profile');
export const PermissionConfigurationProfileRead = createPermission(EntityKind.ConfigurationProfile, PermissionRead);