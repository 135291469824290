import { IsNumber } from 'class-validator';

export class DataRecordingLimits
{
    @IsNumber()
    maxRecordingsInProgress!: number;

    @IsNumber()
    maxRecordingDurationDays!: number;

    @IsNumber()
    maxRecordedReceivers!: number;
}