import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';

export class ReceiverUpdateItem
{
    @IsString()
    @IsNotEmpty()
    expectedArtifactName!: string;

    @IsString()
    @IsNotEmpty()
    receiverId!: string;
}

export class UpdateLatestMctFirmwareDto
{
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => ReceiverUpdateItem)
    receivers!: ReceiverUpdateItem[];
}