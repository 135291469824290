import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplyProfilesDto, GetReceiverConfigDto, ReceiverPasswordDto, SetReceiverConfigDto } from '@involi/api-shared';
import { ApiClient } from '../core/api-client';
import { API_CONFIG, ApiConfig } from '../api';
import { ApiStatusService } from '../core/api-status.service';

@Injectable({
    providedIn: 'root'
})
export class ConclaveApiService extends ApiClient
{
    private apiUrl: string;

    constructor(@Inject(API_CONFIG) config: ApiConfig,
                apiStatus: ApiStatusService,
                http: HttpClient)
    {
        super(apiStatus, http, 'Conclave');
        this.apiUrl = `${config.involiApiUrl}/conclave`;
    }

    getReceiverPassword(serialNumber: string): Observable<ReceiverPasswordDto>
    {
        return this.get<ReceiverPasswordDto>(`${this.apiUrl}/receiver/${serialNumber}/password`);
    }

    getReceiverConfig(serialNumber: string): Observable<GetReceiverConfigDto>
    {
        return this.get<GetReceiverConfigDto>(`${this.apiUrl}/receiver/${serialNumber}/config`);
    }

    updateReceiverConfig(serialNumber: string, newConfig: SetReceiverConfigDto): Observable<void>
    {
        return this.post<void>(`${this.apiUrl}/receiver/${serialNumber}/config`, newConfig);
    }

    applyProfiles(serialNumber: string, profileIds: string[]): Observable<GetReceiverConfigDto>
    {
        const request: ApplyProfilesDto = { profile_ids: profileIds };
        return this.post<GetReceiverConfigDto>(`${this.apiUrl}/receiver/${serialNumber}/profiles`, request);
    }
}
